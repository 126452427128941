<template>
  <validation-provider :name="inputId" mode="eager" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes}">
      <label :for="inputId" :class="labelCSSClass">{{ inputLabel }}</label>
      <div :class="fieldCSSClass">
        <div class="row">
          <div :class="{...monthCSSClass}">
            <Select
              :disabled="disabled"
              :labelClass="{'col-auto':true, 'col-form-label':true}"
              :fieldClass="{}"
              :placeholder="getLocalizedText(computedLabels.month_placeholder)"
              :selectOptions="{
                options: availableMonths,
                getOptionLabel : value => monthsLabel[value],
                multiple: true
              }"
              :required="required"
              v-bind:value.sync="form.month" 
            />
          </div>
          <div :class="{...yearCSSClass}">
            <select class="form-control" v-model="form.year" :required="required" :disabled="disabled">
              <option value="">{{ getLocalizedText(computedLabels.year_placeholder) }}</option>
              <option v-for="(year, index) in listYears" :key="index" :value="year">{{year}}</option>
            </select>
          </div>
        </div>
        <span class="control-label form-text m-b-none" v-if="errors.length > 0">{{ errors[0] }}</span>
      </div>
    </div>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '@fwk-client/components/mixins/GenericInput.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';
import { LocalizedText } from '@igotweb-node-api/api/models/interface/localization';
import { info } from 'pdfkit';

interface MonthInputLabels {
  month_placeholder:LocalizedText;
  year_placeholder:LocalizedText;
}

@Component({
  components : {
    Select
  }
})
export default class MonthInput extends mixins<GenericInput<String>>(GenericInput) {

  @Prop({
    type: Boolean,
    required: false
  }) readonly disabled!: boolean

  @Prop({
    type: Number,
    required: false
  }) readonly startYear!: number

  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) readonly allowYearSelection!: boolean

  @Prop({
    type: Number,
    required: false
  }) readonly endYear!: number

  @Prop({
    type: Object,
    required: false
  }) readonly monthClass!: any

  @Prop({
    type: Object,
    required: false
  }) readonly yearClass!: any

  @Prop({
    type: Object,
    required: false,
  }) readonly labels!: MonthInputLabels | undefined

  inputLabel = "";
  inputPlaceholder = "";
  inputId = "date";

  monthCSSClass:any = this.monthClass ? this.monthClass : {"col-lg-6":true }
  yearCSSClass:any = this.yearClass ? this.yearClass : {"col-lg-6":true }

  form:any = {
    month : [],
    year: ""  
  }

  months = ["01","02","03","04","05","06","07","08","09","10","11","12"]

  monthsLabel = {
    "01" : this.$t('months.january'),
    "02" : this.$t('months.february'),
    "03" : this.$t('months.march'),
    "04" : this.$t('months.april'),
    "05" : this.$t('months.may'),
    "06" : this.$t('months.june'),
    "07" : this.$t('months.july'),
    "08" : this.$t('months.august'),
    "09" : this.$t('months.september'),
    "10" : this.$t('months.october'),
    "11" : this.$t('months.november'),
    "12" : this.$t('months.december')
  }

  created() {
    this.inputLabel = (this.label && this.label != "") ? this.label : "";
    this.inputPlaceholder = (this.placeholder && this.placeholder != "") ? this.placeholder : "";
    if(this.id) { this.inputId = this.id }
    this.updateForm(this.value);
  }

  get listYears() {
    let years = [];
    let endYear = this.endYear ? this.endYear : new Date().getFullYear();
    let startYear = this.startYear ? this.startYear : endYear - 10;
    
    for(let i = endYear; i >= startYear; i--) {
      years.push(i);
    }
    return years;
  }

  get computedLabels():MonthInputLabels {
      return {
        month_placeholder: {
          en: "Month",
          fr: "Mois"
        },
        year_placeholder: {
          en: "Year",
          fr: "Année"
        },
        ...this.labels
      }
  }

  get availableMonths() {
    let months =  this.months.filter((month:any) => {
      // return true;
      return this.form.month.indexOf(month) < 0
    })
    return months;
  }

  updateForm(value:any) {
    if(value && Array.isArray(value) && value.length > 0) {
      if(value.length == 1 && value[0].length == 4) {
        // We update the month and year if needed.
        if(this.form.month.length > 0) {
          this.form.month = [];
        }
        if(this.form.year != value[0]) {
          this.form.year = value[0];
        }
      }
      else {
        // We take the first 2 characters of the value for the month
        // We take the last 4 characters of the value for the year
        this.form.month = value.map((item) => {
          return item.substring(0,2);
        })
        if(this.form.year != value[0].substring(3,7)) {
          this.form.year = value[0].substring(3,7);
        }
      }
    }
    else {
      this.form = {
        month : [],
        year: ""
      }
    }
  }

  @Watch('form', { deep: true })
  onFormChange(val: any, oldVal: any) {
    if(this.allowYearSelection && (val.month == undefined || val.month.length == 0) && (val.year && val.year !== "")) {
        this.$emit('update:value', [val.year+""]);
    }
    else if(val.month && val.month.length > 0 && val.year && val.year != "") {
      let result = [];
      for(let i = 0; i < val.month.length; i++) {
        result.push(val.month[i] + "-" + val.year);
      }
      this.$emit('update:value', result);
    }
    else if(this.value && this.value.length > 0) {
      // We emit the value if current one is not empty
      this.$emit('update:value', []);
    }
  }

  @Watch('value', { deep: true })
  onValueChange(val: any, oldVal: any) {
    if(val && val.length == 0 && oldVal && oldVal.length == 0) {
      return;
    }
    else if(val && oldVal && val.length == oldVal.length) {
      // We check if arrays are the same
      let same = true;
      for (let i = 0; i < val.length; i++) {
        if (val[i] !== oldVal[i]) {
          same = false;
          break;
        }
      }
      if(same) {
        return;
      }
    }
    this.updateForm(val);
  }

}
</script>