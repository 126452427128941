<template>
  <div class="ibox files">
    <div class="ibox-title">
      <h2>{{ getLocalizedText(labels.reportListTitle) }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <div class="row m-b-md">
        <div class="col-lg-6">
          <Select
            v-if="companies.length > 0"
            :labelClass="{'col-auto':true, 'col-form-label':true}"
            :fieldClass="{'col':true}"
            :label="getLocalizedText(labels.companyFilterLabel)"
            :placeholder="getLocalizedText(labels.companyFilterPlaceholder)"
            :selectOptions="{
              options: companies
            }"
            v-bind:value.sync="filters.companyName" 
            :labelAsColumn="true"
          />
        </div>
        <div class="col-lg-6">
          <MonthInput
            :labelClass="{'col-auto':true, 'col-form-label':true}"
            :fieldClass="{'col':true}"
            :label="getLocalizedText(labels.periodFilterLabel)"
            :allowYearSelection="true"
            v-bind:value.sync="filters.periods" 
            :labelAsColumn="true"
          />
        </div>
      </div>

      <div class="row">

        <b-pagination
            v-model="currentPage"
            :total-rows="reports.length"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>

      </div>

      <div class="row">

        <b-table 
            responsive
            outlined striped
            :items="reports"
            :fields="reportsFields"
            :per-page="perPage"
            :current-page="currentPage"
            ref="reportsFiles">
          <template v-slot:cell(options)="row">
            <a @click="row.toggleDetails" href="javascript:void(0)">{{ getLocalizedText(labels.details) }}</a>
            <a v-if="row.item.CSVSource" :href="getFileURL(row.item.CSVSource._id)"><i class="fa fa-file-o"></i></a>
            <a v-if="row.item.XLSReport" :href="getFileURL(row.item.XLSReport._id)"><i class="fa fa-file-excel-o"></i></a>
            <a href="javascript:void(0)" @click="confirmRemoveReport(row.item)"><i class="fa fa-trash-o"></i></a>
          </template>
          <template v-slot:row-details="row">
            <button type="button" @click="row.toggleDetails">{{ getLocalizedText(labels.hideDetails) }}</button><br/>
            <Report :report="row.item" />
            <br/><button type="button" @click="row.toggleDetails">{{ getLocalizedText(labels.hideDetails) }}</button>
          </template>
          <template v-slot:custom-foot v-if="hasFilters">
            <tr role="row">
              <td aria-colindex="1" role="cell" class=""><b>{{ getLocalizedText(labels.totals) }}</b></td>
              <td aria-colindex="2" role="cell" class=""></td>
              <td aria-colindex="3" role="cell" class=""></td>
              <td aria-colindex="4" role="cell" class=""><b>{{ formatPriceAmount({ amount: totalAnalyseEcrituresA1 }) }}</b></td>
              <td aria-colindex="5" role="cell" class=""><b>{{ formatPriceAmount({ amount: totalAnalyseEcrituresE1 }) }}</b></td>
              <td aria-colindex="6" role="cell" class=""><b>{{ formatPriceAmount({ amount: totalAnalyseEcrituresF2 }) }}</b></td>
              <td aria-colindex="7" role="cell" class=""><b>{{ formatPriceAmount({ amount: totalAnalyseEcrituresE3OSS }) }}</b></td>
              <td aria-colindex="8" role="cell" class=""><b>{{ formatPriceAmount({ amount: totalAnalyseEcrituresAutresTaxation }) }}</b></td>
              <td aria-colindex="9" role="cell" class=""><b>{{ formatPriceAmount({ amount: totalAnalyseComminglingsBUY }) }}</b></td>
              <td aria-colindex="10" role="cell" class=""><b>{{ formatPriceAmount({ amount: totalAnalyseComminglingsSELL }) }}</b></td>
              <td aria-colindex="11" role="cell" class=""></td>
            </tr>
          </template>
        </b-table>

      </div>

    </div>

    <b-modal ref="removeReportModal" 
        hide-header
        @ok="removeReport">
      {{ 
        confirmRemoveReportTitle
      }}
    </b-modal>

  </div>
</template>

<style>
  .ibox.files .b-table .options a:has(i) {
    font-size: 1.3em;
    margin-right: 10px;
  }

  .ibox.files .vs__search {
    width: 100%;
  }
</style>



<script lang="ts">
import Messages from '@root/src/client/components/panels/Messages.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';
import MonthInput from '@root/src/client/components/panels/input/MonthInput.vue';
import Report from './report/Report.vue';

import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, nextTick, ComputedRef} from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { formatDay, formatDate, FormatDateType, FromLocalToUTC, formatDateForInput, formatMonth } from '@igotweb-node-api-utils/formatter';
import { languagesTypes } from '@fwk-client/store/types';
import { formatPrice } from '@igotweb-node-api-utils/formatter';

import { useModuleAdmin } from '../../composables/useModuleAdmin';
import { useGenerateAdmin } from '../../composables/useGenerateAdmin';
import { useReportAdmin } from '../../composables/useReportAdmin';

export default defineComponent({
  props: {
      
  },
  components: {
    Messages,
    Select,
    Report,
    MonthInput
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { selectedModule, modules } = useModuleAdmin(props, context);
    const { generateForm, checkFile, generateReport, isListLoading:isGenerateLoading } = useGenerateAdmin(props, context);
    const { reports, updateListReports, removeReport:removeReportAdmin, getReportURL, filters, hasFilters, isListLoading, companies } = useReportAdmin(props, context);
    updateListReports();
     
    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const perPage:number = 10;
    const currentPage:Ref<number> = ref(1);

    const reportsFields = [
      {
        key: "context.companyName",
        label: ""
      },
      {
        key: "period",
        label: "",
        formatter: (value:any, key:any, item:any) => {
          return formatMonth(item.context.periodLastDay, currentLanguageCode.value, {
            isUTC: true
          });
        }
      },
      {
        key: "formattedCreationDateTime",
        label: ""
      },
      {
        key: "analyseEcritures.A1",
        label: "A1",
        formatter: (value:any, key:any, item:any) => {
          return formatPrice(value, currentLanguageCode.value);
        }
      },
      {
        key: "analyseEcritures.E1",
        label: "E1",
        formatter: (value:any, key:any, item:any) => {
          return formatPrice(value, currentLanguageCode.value);
        }
      },
      {
        key: "analyseEcritures.F2",
        label: "F2",
        formatter: (value:any, key:any, item:any) => {
          return formatPrice(value, currentLanguageCode.value);
        }
      },
      {
        key: "analyseEcritures.E3OSS",
        label: "E3 OSS",
        formatter: (value:any, key:any, item:any) => {
          return formatPrice(value, currentLanguageCode.value);
        }
      },
      {
        key: "analyseEcritures.AutresTaxation",
        label: "Autres Taxation",
        formatter: (value:any, key:any, item:any) => {
          return formatPrice(value, currentLanguageCode.value);
        }
      },
      {
        key: "analyseComminglings.BUY",
        label: "C. BUY",
        formatter: (value:any, key:any, item:any) => {
          return formatPrice(value, currentLanguageCode.value);
        }
      },
      {
        key: "analyseComminglings.SELL",
        label: "C. SELL",
        formatter: (value:any, key:any, item:any) => {
          return formatPrice(value, currentLanguageCode.value);
        }
      },
      {
        key: "options",
        label: "",
        class: "options"
      }
    ];

    const removeReportModal:Ref<HTMLElement|null> = ref(null);
    const reportsFiles:Ref<HTMLElement|null> = ref(null);

    const labels = {
      "companyFilterLabel" : {
        "en": "Company",
        "fr": "Entreprise"
      },
      "companyFilterPlaceholder" : {
        "en": "Select a company",
        "fr": "Sélectionner une entreprise"
      },
      "periodFilterLabel" : {
        "en": "Period",
        "fr": "Période"
      },
      "headerCompanyName" : {
        "en": "Company name",
        "fr": "Nom de l'entreprise"
      },
      "headerPeriod" : {
        "en": "Period",
        "fr": "Période"
      },
      "headerCreationDateTime" : {
        "en": "Creation date",
        "fr": "Date de création"
      },
      "headerOptions" : {
        "en": "Options",
        "fr": "Options"
      },
      "confirmRemoveReport" : {
        "en": "Are you sure you want to remove the report for the company {0}?",
        "fr": "Êtes-vous sûr de vouloir supprimer le rapport pour l'entreprise {0}?"
      },
      "reportListTitle": {
        "en": "Generated reports",
        "fr": "Rapports générés"
      },
      "totals": {
        "en": "Totals",
        "fr": "Totaux"
      },
      "details": {
        "en": "Details",
        "fr": "Détails"
      },
      "hideDetails": {
        "en": "Hide Details",
        "fr": "Masquer les détails"
      }
    }

    const confirmRemoveReportTitle = computed(() => {
      if(reportToRemove.value && reportToRemove.value.context) {
        console.log('reportToRemove:'+reportToRemove.value.context.companyName);
      }
      return app.getLocalizedText(labels.confirmRemoveReport,{
        params : (reportToRemove.value && reportToRemove.value.context) ? [reportToRemove.value.context.companyName] : undefined
      })
    })
    

    const updateTableLabels = () => {
      reportsFields[0].label = app.getLocalizedText(labels.headerCompanyName) as string;
      reportsFields[1].label = app.getLocalizedText(labels.headerPeriod) as string;
      reportsFields[2].label = app.getLocalizedText(labels.headerCreationDateTime) as string;
      reportsFields[10].label = app.getLocalizedText(labels.headerOptions) as string;
    }

    const totalAnalyseEcrituresA1 = computed(() => {
      return reports.value.reduce((acc:any, report:any) => {
        return acc + report.analyseEcritures.A1;
      }, 0);
    })

    const totalAnalyseEcrituresE1 = computed(() => {
      return reports.value.reduce((acc:any, report:any) => {
        return acc + report.analyseEcritures.E1;
      }, 0);
    })

    const totalAnalyseEcrituresF2 = computed(() => {
      return reports.value.reduce((acc:any, report:any) => {
        return acc + report.analyseEcritures.F2;
      }, 0);
    })

    const totalAnalyseEcrituresE3OSS = computed(() => {
      return reports.value.reduce((acc:any, report:any) => {
        return acc + report.analyseEcritures.E3OSS;
      }, 0);
    })

    const totalAnalyseEcrituresAutresTaxation = computed(() => {
      return reports.value.reduce((acc:any, report:any) => {
        return acc + report.analyseEcritures.AutresTaxation;
      }, 0);
    })

    const totalAnalyseComminglingsBUY = computed(() => {
      return reports.value.reduce((acc:any, report:any) => {
        return acc + (report.analyseComminglings ? report.analyseComminglings.BUY : 0);
      }, 0);
    })

    const totalAnalyseComminglingsSELL = computed(() => {
      return reports.value.reduce((acc:any, report:any) => {
        return acc + (report.analyseComminglings ? report.analyseComminglings.SELL : 0);
      }, 0);
    })

    onMounted(() => {
      updateTableLabels();
    });

    watch(
      currentLanguageCode,
      (val:any, oldVal:any) => {
      updateTableLabels();
      },
      { deep: false }
    )

    watch(
      () => filters,
      (val:any, oldVal:any) => {
        updateListReports();
      },
      { deep: true }
    )

    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value || isGenerateLoading.value;
    })
    
    const emptyReport = {};
    const reportToRemove:Ref<any> = ref({...emptyReport});

    const getFileURL = (fileId:any) => {
      var url = getReportURL(fileId);
      return url;
    }

    const onReportRemoved = () => {
      updateListReports();
    }

    const confirmRemoveReport = (report:any) => {
      reportToRemove.value = report;
      // @ts-ignore
      removeReportModal.value.show()
    }

    const removeReport = () => {

      removeReportAdmin(reportToRemove.value._id).then((result:any) => {
        if(result.removed) {
          // We update the list of files
          onReportRemoved();
        }
        // We reset the report to remove
        reportToRemove.value = {...emptyReport};

      });
    }

    return { 
      listLoading,
      reports,
      reportsFields,
      perPage,
      currentPage,
      filters,
      companies,
      reportsFiles,
      getFileURL,
      confirmRemoveReport,
      removeReport,
      removeReportModal,
      reportToRemove,
      labels,
      confirmRemoveReportTitle,
      totalAnalyseEcrituresA1,
      totalAnalyseEcrituresE1,
      totalAnalyseEcrituresF2,
      totalAnalyseEcrituresE3OSS,
      totalAnalyseEcrituresAutresTaxation,
      totalAnalyseComminglingsBUY,
      totalAnalyseComminglingsSELL,
      hasFilters
    }
  }
})
</script>